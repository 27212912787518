import React, { useEffect, useRef } from "react";

// Define any custom types needed for the Google IMA SDK
declare global {
  interface Window {
    google: any;
  }
}

const GoogleAdStream: React.FC<{ className?: string }> = ({ className }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const adsLoaderRef = useRef<any>(null);
  const adsManagerRef = useRef<any>(null);
  const adDisplayContainerRef = useRef<any>(null);

  useEffect(() => {
    const initIMA = () => {
      const videoElement = videoRef.current;

      if (!videoElement) {
        console.error("Video element not found");
        return;
      }

      // Create the ad display container
      adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(
        document.getElementById("ad-container"),
        videoElement
      );

      // Initialize the ad display container (may require user interaction in some cases)
      adDisplayContainerRef.current.initialize();

      // Create ads loader
      adsLoaderRef.current = new window.google.ima.AdsLoader(
        adDisplayContainerRef.current
      );

      // Add ads loader event listeners
      adsLoaderRef.current.addEventListener(
        window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        onAdsManagerLoaded,
        false
      );
      adsLoaderRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError,
        false
      );

      // Request video ads
      const adsRequest = new window.google.ima.AdsRequest();
      const now = new Date().getTime();
      adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?sz=336x280|300x250|400x300|480x720|1024x768&iu=/24458126/O2A_Preroll_web&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=https://12all.tv&description_url=https://12all.tv&correlator=${now}`;

      adsRequest.linearAdSlotWidth = videoElement.clientWidth;
      adsRequest.linearAdSlotHeight = videoElement.clientHeight;
      adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
      adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

      console.log("Requesting ads...");
      adsLoaderRef.current.requestAds(adsRequest);
    };

    const onAdsManagerLoaded = (adsManagerLoadedEvent: any) => {
      console.log("AdsManager loaded event received.");
      adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
        videoRef.current
      );

      adsManagerRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        onContentPauseRequested
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
        onContentResumeRequested
      );

      // Add an event listener for when all ads are completed
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        onAllAdsCompleted
      );

      try {
        adsManagerRef.current.init(
          videoRef.current!.clientWidth,
          videoRef.current!.clientHeight,
          window.google.ima.ViewMode.NORMAL
        );
        adsManagerRef.current.start();
        console.log("AdsManager started.");
      } catch (adError) {
        console.error("AdsManager could not be started:", adError);
      }
    };

    const onAdError = (adErrorEvent: any) => {
      console.error("Ad error:", adErrorEvent.getError());
      adsManagerRef.current?.destroy();
    };

    const onContentPauseRequested = () => {
      console.log("Content pause requested.");
      videoRef.current?.pause();
    };

    const onContentResumeRequested = () => {
      console.log("Content resume requested.");
      // Prevent the content from resuming after ads
      videoRef.current?.pause();
    };

    const onAllAdsCompleted = () => {
      console.log("All ads completed. Restarting ads.");
      adsManagerRef.current?.destroy(); // Destroy current ad manager
      initIMA(); // Re-initialize IMA SDK to request ads again
    };

    const tryAutoplay = async () => {
      try {
        await videoRef.current?.play();
        console.log("Video autoplayed successfully");
      } catch (error) {
        console.warn(
          "Autoplay failed. User interaction required to start ads.",
          error
        );
      }
    };

    tryAutoplay(); // Attempt to autoplay the video
    initIMA(); // Initialize IMA SDK for ads

    return () => {
      adsManagerRef.current?.destroy();
    };
  }, []);

  return (
    <div className="ads-container">
      <div id="ad-container" className={className}></div>
      <video id="video-player" ref={videoRef} />
    </div>
  );
};

export default GoogleAdStream;
